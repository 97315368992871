
export function slider() {
	var carousel = $('.owl-carousel');
	if (carousel.length != 0) {
		$(document).ready(function () {
			carousel.owlCarousel({
				items: 3,
				margin: 30,
				stagePadding: 150,
				dots: true,
				loop: true,
				navigation: true,
				navText: ['<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m15 18l-6-6l6-6"/></svg>','<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m9 18l6-6l-6-6"/></svg>'],
				responsive: {
					0: {
						items: 1,
						stagePadding: 50
					},
					600: {
						items: 1,
						stagePadding: 50
					},
					1000: {
						items: 2,
					}
				}
			});
		});

	}
}

export default slider;