/*
 * @title App
 * @description Application entry point
 */

// Modules
import inview from './components/inviewport';
import navbar from './components/navbar';
import slider from './components/slider';

document.addEventListener('DOMContentLoaded', function() {
  inview();
  navbar();
  slider();
})

